@media screen and (min-width : 768px) {
     #BestServices {
          .card {
               &:nth-child(even) {
                    margin-top: 4rem;
               }

               &:hover {

                    p,
                    h3 {
                         color: #fff;
                    }
               }
          }
     }
}

.avatar {
     background: linear-gradient(25deg, rgb(30 194 227) 0%, rgb(255 255 255) 50%, rgb(30 194 227) 100%);
}