 
.CoverClass{
          transition: all 0.5s ease-in-out;
          &:hover{
                    border: 2px solid transparent;
                    background: transparent;
                    button{
                              border: 2px solid #fff;
                    }
          }
}
// @media screen and (min-width : 2000px) {
//           .OutlineBtn{
//                     margin-top: 4rem;
//           }
// }