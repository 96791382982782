@tailwind base;
@tailwind components;
@tailwind utilities;  
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');


.scrollbar-none::-webkit-scrollbar {
  width: 0
}

::-webkit-scrollbar {
  width: 5px
}

::-webkit-scrollbar-thumb {
  background: #777
}

::-webkit-scrollbar-thumb:hover {
  background: #555
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-size: 62.5%;
}

*,
::after,
::before {
  box-sizing: border-box;
  outline: none;
  box-shadow: none;
}
 
body{
  font-family: 'Poppins', sans-serif;
  padding-top: 6rem;
}

.alert{
  width: 80rem;
  z-index: 999999999999;
}
.error{
  min-height: 24rem;
  z-index: 999999999999;

}
.errorTitle{
  height: 6rem;
  margin-bottom: 2rem;
} 